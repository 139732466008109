import { Injectable } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { addDays, isAfter, isBefore, isEqual } from 'date-fns';
import { combineLatest, filter, lastValueFrom, Observable, startWith } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccountingYear } from '@dougs/accounting-years/dto';
import { AccountingYearStateService } from '@dougs/accounting-years/shared';
import { Company } from '@dougs/company/dto';
import { COMPANY_FLAG, CompanyStateService } from '@dougs/company/shared';
import { MetricsService } from '@dougs/core/metrics';
import { RoutingService, URL } from '@dougs/core/routing';
import { ModalService } from '@dougs/ds';
import { SERVICE_STATE } from '@dougs/revenue/services/dto';
import { CompanyServicesStateService } from '@dougs/revenue/services/shared';
import { ServicesStateService } from '@dougs/services/shared';
import { COGEP_ACCOUNTING_FIRM_ID } from '@dougs/settings/shared';
import { Task } from '@dougs/task/dto';
import { UserTasksStateService } from '@dougs/task/shared';
import { User } from '@dougs/user/dto';
import { USER_FLAG, UserStateService } from '@dougs/user/shared';
import { AccountingFirmStateService } from '@dougs/white-label/shared';
import { SupportModalComponent } from '../modals/support-modal/support-modal.component';

@Injectable()
export class SidebarComponentService {
  private readonly URLS_WITHOUT_BANNER: string[] = [URL.TASKS, URL.ACCOUNTING_REVIEW];
  private readonly URL_SEGMENT_POSITION = 3;

  constructor(
    private readonly companyServicesStateService: CompanyServicesStateService,
    private readonly modalService: ModalService,
    private readonly companyStateService: CompanyStateService,
    private readonly userStateService: UserStateService,
    private readonly servicesStateService: ServicesStateService,
    private readonly accountingYearStateService: AccountingYearStateService,
    private readonly userTasksStateService: UserTasksStateService,
    private readonly accountingFirmStateService: AccountingFirmStateService,
    private readonly routingService: RoutingService,
    public readonly metricsService: MetricsService,
    private readonly router: Router,
  ) {}

  logo$: Observable<string> = combineLatest([
    this.companyStateService.activeCompanyIdChanged$,
    this.userStateService.activeUserIdChanged$,
  ]).pipe(map(([activeCompany, activeUser]) => this.getLogo(activeUser, activeCompany)));

  relevantOnboardingCreationUrl$: Observable<string> = this.userStateService.activeUser$.pipe(
    map((activeUser) => this.routingService.getRelevantOnboardingCreaUrl(activeUser).toString()),
  );

  showTrial$: Observable<boolean> = combineLatest([
    this.companyStateService.activeCompany$,
    this.companyServicesStateService.services$,
    this.userStateService.activeUser$,
  ]).pipe(
    map(
      ([activeCompany, services, activeUser]) =>
        !!activeCompany?.isCreated &&
        !!services?.accounting?.isTrialing &&
        !services?.accounting?.serviceDescriptor?.name &&
        !activeUser?.isEligibleToComptastart,
    ),
  );

  countServicesUncompleted$: Observable<number> = this.servicesStateService.services$.pipe(
    map((services) => services?.filter((service) => !service.completedAt).length || 0),
  );

  shouldShowServicesLink$: Observable<boolean> = combineLatest([
    this.companyStateService.activeCompany$,
    this.servicesStateService.services$,
    this.companyServicesStateService.services$,
  ]).pipe(
    map(
      ([activeCompany, services, servicesState]) =>
        !!activeCompany &&
        (!!services?.length ||
          ((servicesState.accounting.isActive === true || servicesState.accounting.isTrialing === true) &&
            activeCompany.flags.includes(COMPANY_FLAG.hasAccessSelfServices))),
    ),
  );

  shouldShowNewBadgeOnServices$: Observable<boolean> = combineLatest([
    this.countServicesUncompleted$,
    this.companyStateService.activeCompany$,
    this.companyServicesStateService.services$,
    this.userStateService.activeUser$,
  ]).pipe(
    map(
      ([countServicesUncompleted, activeCompany, servicesState, activeUser]) =>
        countServicesUncompleted === 0 &&
        activeCompany.flags.includes(COMPANY_FLAG.hasAccessSelfServices) &&
        (servicesState.accounting.isActive === true || servicesState.accounting.isTrialing === true) &&
        !activeUser.metadata.hasSeenNewServiceCatalog,
    ),
  );

  shouldShowAccountingSurveyReminder$: Observable<boolean> = this.userTasksStateService.tasks$.pipe(
    map((userTasks) =>
      userTasks?.length
        ? this.shouldShowAccountingSurveyReminder(userTasks.find((task) => task.code === 'customer:accountingSurvey'))
        : false,
    ),
  );

  shouldShowAccountingOnboarderAppointmentNotification$ = this.userStateService.activeUser$.pipe(
    map((user) => user.flags.includes(USER_FLAG.HAS_ACCOUNTING_ONBOARDER)),
  );

  accountingSurveyCompletion$: Observable<number | null> = combineLatest([
    this.accountingYearStateService.activeAccountingYear$,
    this.userTasksStateService.tasks$,
  ]).pipe(
    map(([activeAccountingYear, userTasks]) =>
      this.getAccountingSurveyCompletion(
        activeAccountingYear,
        userTasks?.find((task) => task.code === 'customer:accountingSurvey'),
      ),
    ),
  );

  shouldShowCompanyLink$: Observable<boolean> = this.userStateService.loggedInUser$.pipe(
    map(
      (loggedInUser) =>
        loggedInUser.isAccountantOrAdmin &&
        (!this.accountingFirmStateService.isInternalAccountingFirm(loggedInUser.accountingFirmId) ||
          loggedInUser.flags.includes('canSeeNewCompaniesModule')),
    ),
  );

  shouldShowPresenceMember$: Observable<boolean> = this.userStateService.loggedInUser$.pipe(
    map((loggedInUser) => this.accountingFirmStateService.isInternalAccountingFirm(loggedInUser.accountingFirmId)),
  );

  canSeeBanner$: Observable<boolean> = this.router.events.pipe(
    startWith(new NavigationEnd(0, this.router.url, this.router.url)),
    filter((event: Event): event is NavigationEnd => !!event && event instanceof NavigationEnd),
    map((event) => {
      const urlMatches: RegExpMatchArray | null = event.url.match(/[^/]+/g);

      if (urlMatches && urlMatches.length >= this.URL_SEGMENT_POSITION) {
        const lastUrlSegment: string = urlMatches[this.URL_SEGMENT_POSITION - 1];
        return !this.URLS_WITHOUT_BANNER.some((url) => lastUrlSegment.includes(url));
      }

      return true;
    }),
  );

  private getLogo(activeUser: User, activeCompany: Company): string {
    if (activeCompany?.accountingFirmId === COGEP_ACCOUNTING_FIRM_ID) {
      return 'cogep-logo';
    }

    if (activeUser?.isEligibleToComptastart) {
      return 'dougs-comptastart-logo';
    }

    if (this.userStateService.hasDarkModeEnabled()) {
      return 'dougs-logo-light';
    }

    return 'dougs-logo';
  }

  private getAccountingSurveyCompletion(
    activeAccountingYear: AccountingYear,
    accountingSurveyTask?: Task,
  ): number | null {
    if (activeAccountingYear?.survey?.completionPercent) {
      return activeAccountingYear?.survey?.completed ? null : activeAccountingYear.survey.completionPercent;
    }

    return accountingSurveyTask?.metadata?.completionPercent || null;
  }

  private shouldShowAccountingSurveyReminder(accountingSurveyTask?: Task): boolean {
    return (
      !!accountingSurveyTask?.metadata &&
      !accountingSurveyTask?.metadata.preventNotifications &&
      !accountingSurveyTask?.metadata.isLiquidation &&
      isAfter(new Date(), new Date(accountingSurveyTask?.metadata.closingDate)) &&
      (isBefore(new Date(), addDays(new Date(accountingSurveyTask?.dueDate), 15)) ||
        isEqual(new Date(), addDays(new Date(accountingSurveyTask?.dueDate), 15)))
    );
  }

  async openUserModal(): Promise<void> {
    const { UserSearchModalComponent } = await import('@dougs/user/ui');
    await lastValueFrom(this.modalService.open(UserSearchModalComponent).afterClosed$);
  }

  openSupportModal(): void {
    this.modalService.open(SupportModalComponent);
  }

  sendClickMenuEvent(componentMenuClicked: string): void {
    const serviceActivated: string =
      this.companyServicesStateService.services?.accounting?.state === SERVICE_STATE.ACTIVATED ||
      this.companyServicesStateService.services?.accounting?.state === SERVICE_STATE.SUSPENDED ||
      this.companyServicesStateService.services?.accounting?.state === SERVICE_STATE.ONBOARDING
        ? `Accounting`
        : this.companyServicesStateService.services?.creation?.state === SERVICE_STATE.ACTIVATED
          ? `Creation`
          : `Invoicing`;

    this.metricsService.pushMixpanelEvent(`Menu ${serviceActivated} ${componentMenuClicked} Link Clicked`);
  }
}
